import github_icon from '../imgs/github.svg'
import linkedin_icon from '../imgs/linkedin.svg'

import '../Styles/Footer.scss'

const Footer = () => {
	return (
		<div className={'footer'}>
			<div className={'socials'}>
				<a style={{gridColumnStart: 2}}
				   href="https://github.com/andrearizzello"
				   target={'_blank'}
				   rel="noreferrer">
					<img src={github_icon} width={20} height={20} alt="github"/>
				</a>
				<a style={{gridColumnStart: 4}}
				   href="https://www.linkedin.com/in/andrearizzell0/"
				   target={'_blank'}
				   rel="noreferrer">
					<img src={linkedin_icon} width={20} height={20} alt="linkedin"/>
				</a>
			</div>
			<p className={'built-with-icon'}>Built with<span/> and pure CSS
				<br/>(no graphic libraries were used for this website)</p>
			<p>Special thanks to&nbsp;
				<a href={'https://www.flaticon.com/'}
				   target={'_blank'}
				   rel="noreferrer"
				   className={'refer-icons'}>@FlatIcon</a>
				&nbsp;and&nbsp;
				<a href="https://www.vectorlogo.zone"
				   target={'_blank'}
				   rel='noreferrer'
				   className={'refer-icons'}>@VectorLogo</a>
				&nbsp;for the icons</p>
		</div>
	)
}

export default Footer;
