const urlsItems = [
	{
		name: 'Home',
		url: '/'
	},
	// {
	// 	name: 'My CV',
	// 	url: '/cv'
	// },
]

export default urlsItems
