import {Fragment, lazy, Suspense, useEffect} from "react";
import Header from "./Containers/Header";
import Footer from "./Containers/Footer";
import FallbackLoader from "./Components/FallbackLoader";
import {Route, Routes, useLocation} from "react-router-dom";
import urlsItems from "./utils/urls";
import {careerTitle} from "./utils/careerTitle";

const Home = lazy(() => import('./Containers/Home').then())
// const CV = lazy(() => import('./Containers/CV').then())


const App = () => {
	const location = useLocation()

	const getCurrPageName = page => urlsItems.find(url => url.url === page).name || ''

	useEffect(() => {
		document.title = `Andrea's portfolio | ${careerTitle.title} - ${getCurrPageName(location.pathname)}`
	}, [location.pathname])

	return (
		<Fragment>
			<Suspense fallback={<FallbackLoader/>}>
				<Routes>
					<Route exact path="/" element={
						<Fragment>
							<Header/>
							<Home/>
							<Footer/>
						</Fragment>
					}/>
					{/*<Route exact path="/cv" element={*/}
					{/*	<Fragment>*/}
					{/*		<Header/>*/}
					{/*		<CV/>*/}
					{/*	</Fragment>*/}
					{/*}/>*/}
				</Routes>
			</Suspense>
		</Fragment>
	)
}

export default App;
