import {useEffect} from "react";
import {NavLink} from "react-router-dom";
import urlsItems from "../utils/urls";

import '../Styles/Sidebar.scss'

const Sidebar = ({visible, onClick}) => {
	const getListItems = () =>
		urlsItems.map((item, index) =>
			<NavLink key={index}
					 to={item.url}
					 className={'sidebar-inner-a'}
					 end>
				<p>{item.name}</p>
			</NavLink>
		)

	useEffect(() => {
		document.body.style.overflow = visible ? "hidden" : "unset"
	}, [visible])

	return (
		<div id={'outer'} className={visible ? 'sidebar-outer-dark active' : 'sidebar-outer-dark'} onClick={onClick}>
			<div id={'inner'} className={visible ? 'sidebar-inner active' : 'sidebar-inner'}>
				<h1 id={'pages'}>Pages</h1>
				{getListItems()}
			</div>
		</div>
	)
}

export default Sidebar;
