import {NavLink} from "react-router-dom";

import '../Styles/Item.css'

const Item = ({data}) => {
	return (
		<NavLink to={data.url}
				 className={({isActive}) => 'spacing visible' + (isActive ? ' item-active' : '')}
				 end>
			<p>{data.name}</p>
		</NavLink>
	)
}

export default Item;
