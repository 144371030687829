import {useState} from "react";
import {NavLink} from "react-router-dom";
import Sidebar from "../Components/Sidebar";
import Item from "../Components/Item";
import urlsItems from "../utils/urls";

import '../Styles/Header.scss'

const Header = () => {
	const [visible, setVisible] = useState(false)

	const setVisibility = event => {
		if (!['inner', 'pages'].includes(event.target.id))
			setVisible(!visible)
	}
	const getItems = () => urlsItems.map((element, index) =>
		<Item key={index}
			  data={element}
		/>
	)

	return (
		<div className={'flex'}>
			<div style={{flexGrow: 1}}>
				<NavLink to="/" className={'navlink'}>
					<h1 className={'logo'}>andrearizzello<span className={'dot-span'}>.</span>work</h1>
				</NavLink>
			</div>
			{getItems()}
			<div className={'burger'} onClick={event => setVisibility(event)}>
				<div className={'burger-line'}/>
				<div className={'burger-line'}/>
				<div className={'burger-line'}/>
			</div>
			<Sidebar visible={visible} onClick={setVisibility}/>
		</div>
	)
}

export default Header;
