import '../Styles/fallbackLoader.scss'

const FallbackLoader = () => {
	return (
		<div className='fallback-container'>
			<div className='fallback-loader'/>
			<p>Loading&nbsp;</p>
		</div>
	)
}

export default FallbackLoader
